

































































































































































































































































































































































































































































































































































































.main_actions_buttons_wrapper {
  margin: 30px 0;
  .pre_buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
    flex-wrap: wrap;
    .custom_btn {
      background-color: #23ccef;
      border: 1px solid #23ccef;
      color: #fff;
      padding: 8px 20px;
      min-width: 120px;
      border-radius: 8px;
      &.is_disabled {
        cursor: no-drop;
        opacity: 0.5;
      }
      &.examination {
        background-color: #fb404b;
        border-color: #fb404b;
      }
      &.correction {
        background-color: #87cb16;
        border-color: #87cb16;
      }
      &.delete {
        background-color: #ffa534;
        border-color: #ffa534;
      }
    }
  }
}
.gap_btn {
  gap: 10px;
}
// Tabs Wrapper
.tabs_wrapper {
  display: flex;
  gap: 20px;
  padding: 20px;
  margin: 10px;
  border-radius: 5px;

  .tab_button {
    background-color: gray;
    border: 1px solid gray;
    color: #fff;
    padding: 8px 20px;
    min-width: 120px;
    border-radius: 8px;
    transition: 0.3s;
    &.active {
      background-color: #23ccef;
      border-color: #23ccef;
    }
    &:hover {
      background-color: #23ccef;
      border-color: #23ccef;
      opacity: 0.7;
    }
  }
}
// Exams Wrapper
// reset_button
.reset_button {
  background-color: #ffa534;
  border: 1px solid #ffa534;
  color: #fff;
  padding: 8px 20px;
  min-width: 100px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  &.correction {
    background-color: #87cb16;
    border-color: #87cb16;
  }
  &:hover {
    // background-color: #23ccef;
    // border-color: #23ccef;
    opacity: 0.7;
  }
}
// Exam One
.main_for_part_one {
  margin: 20px;
}
.pre_one_wrapper {
  margin: 10px 10px 20px;
  border-radius: 5px;
  display: flex;
  gap: 20px;
  // images Style
  .images_wrapper {
    flex-grow: 1;
    background: #fbfbfb;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    padding: 20px 0;
    .every_img_wrapper {
      margin-bottom: 20px;
      .main_img {
        width: 200px;
        height: 200px;
        background: #fff;
        position: relative;
        display: flex;
        flex-wrap: wrap;

        .item {
          display: block;
          width: 40px;
          height: 40px;
        }
      }
      .img_name {
        padding: 8px;
        text-align: center;
        text-transform: capitalize;
        background: #fff;
      }
    }
  }
  // answers_repeat
  .answers_repeat {
    min-width: 250px;
    background: #fbfbfb;
    padding: 20px 8px;
    .title {
      font-size: 18px;
      margin-bottom: 20px;
      color: #555;
    }
    .repeat_item {
      display: flex;
      justify-content: space-between;
      background: #fff;
      margin-top: 10px;
      padding: 10px;
      border-radius: 5px;
      .name {
        color: #777;
        text-transform: capitalize;
      }
      .answer_count {
        background-color: #87cb16;
        color: #fff;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// Exam Two
.pre_two {
  margin: 10px 10px 20px;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.page-btn {
  margin: 0 10px;
  padding: 5px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}
.page-btn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

